<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <page-loading :loading="initializing" />

    <m-card v-if="!initializing" :title="$t('account.completeYourProfile')" id="user-profile">
      <v-form>
        <v-row dense>
          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.user.firstName" :label="$t('shared.firstName')" :rules="'required|max:30'" dir="auto" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.user.lastName" :label="$t('shared.lastName')" dir="auto" :rules="'required|max:30'" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <mobile-input
              v-model="model.user.mobile"
              :code.sync="model.user.countryCode"
              dir="ltr"
              :label="$t('shared.mobile')"
              :rules="'required|numeric'"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input v-model="model.user.email" :label="$t('shared.email')" dir="ltr" :rules="'email'" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <c-input select v-model="model.profile.gender" :label="$t('users.gender')" :rules="'required'" :items="genderItems" />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <date-picker v-model="model.profile.birthDate" :label="$t('users.birthDate')" :name="$t('users.birthDate')" />
          </v-col>

          <v-col cols="12" sm="6" md="4" class="d-flex align-center">
            <v-switch v-model="model.user.mobileConfirmed" :label="$t('users.mobileConfirmed')" inset />
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <page-footer edit @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  name: 'profile',

  data() {
    return {
      progressing: false,
      initializing: true,
      genderItems: [
        {text: this.$t('shared.male'), value: 'MALE'},
        {text: this.$t('shared.female'), value: 'FEMALE'}
      ],
      model: {
        profile: {
          academicYear: null,
          birthDate: null,
          city: null,
          fieldOfStudy: null,
          freeConsultation: false,
          gender: null,
          image: null,
          parent: false,
          province: null
        },
        twoFactorEnabled: false,
        user: {
          email: null,
          firstName: null,
          lastName: null,
          mobile: null,
          twoFactorEnabled: false
        }
      }
    }
  },
  created() {
    this.getProfile()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    ...mapMutations({updateAccount: 'UPDATE_ACCOUNT'}),
    getProfile() {
      return this.$api.account.profile().then((res) => {
        this.model = res
      })
    },
    submit() {
      return this.$api.account
        .updateProfile(this.model)
        .then((res) => {
          this.updateAccount(res)
          this.model = res
          this.$showSuccess(this.$t('account.profileUpdated'))
        })
        .catch((err) => {
          this.$showError(err)
        })
    }
  }
}
</script>
